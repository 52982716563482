import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { deleteEmptyData } from 'src/app/commons/app-utils';
import { ComunService } from 'src/app/services/comun-service';

declare interface TableData {
  headerRow: string[];
  dataRows: Array<any>;
}

@Component({
  selector: 'app-buscador-ifi',
  templateUrl: './buscador-ifi.component.html',
  styleUrls: ['./buscador-ifi.component.css']
})
export class BuscadorIfiComponent implements OnInit {
  public tableData1: TableData;
  searchForm: FormGroup;
  
  countG: number = 5;
  pageG: number = 1;

  length = 100;
  pageIndex: any;
  pageSize = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(public dialogRef: MatDialogRef<BuscadorIfiComponent>,
    private comunService: ComunService,
    private formBuilder: FormBuilder) { 

      this.searchForm = this.formBuilder.group({
        nroifi: [''],
        nombre: [''],
        ruc: [''],
        nroContrato: ['']
      });
    }

  ngOnInit(): void {
    this.pageG = 1;
    this.countG = 5;
    this.tableData1 = {
      headerRow: ['NÚMERO DE IFI', 'Nombre', 'Ruc', 'Número de Contrato'],
      dataRows: []
    };
    this.listarIFIS();
  }

  listarIFIS() {
    let sortBy = 'idifi';
    let sortOrder = 'ASC';
    let filtro = {
      count: this.countG,
      page: this.pageG,
      sortBy: sortBy,
      sortOrder: sortOrder,
      filters: JSON.stringify(null)
    };
    this.obtenerDatos(filtro);
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

  clearForm() {
    this.listarIFIS();
  }

  apiServices() {

    var filter = {}

    if (this.searchForm.value.nroifi == ''
      && this.searchForm.value.nombre == ''
      && this.searchForm.value.ruc == ''
      && this.searchForm.value.nroContrato == '')

      filter = null;
    else {
      filter = deleteEmptyData(this.searchForm.value);
    }

    let sortBy = 'idifi';
    let sortOrder = 'ASC';

    let filtro = {
      count: this.countG,
      page: this.pageG,
      sortBy: sortBy,
      sortOrder: sortOrder,
      filters: JSON.stringify(filter)
    };

    this.obtenerDatos(filtro);
  }

 

  callPaginationRoles($event) {
    this.countG = $event.pageSize;
    this.pageG = $event.pageIndex + 1;
    this.apiServices();
  }

  private obtenerDatos(filtro: { count: number; page: number; sortBy: string; sortOrder: string; filters: string; }) {
    this.comunService.get(filtro, 'dbo.ifi').subscribe((data) => {
      if (data) {
        this.length = data.count;
        this.tableData1.dataRows = [];
        data.rows.forEach(element => {
          this.tableData1.dataRows.push(element);
        });
      }

    });
  }

}
