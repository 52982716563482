import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-fallo-dialog',
  templateUrl: './fallo-dialog.component.html',
  styleUrls: ['./fallo-dialog.component.css']
})
export class FalloDialogComponent2 implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      confirmText: string;
      messages: string[];
      title: string;
    },
    private mdDialogRef: MatDialogRef<FalloDialogComponent2>
  ) { }

  ngOnInit(): void {
  }

   public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }

}
