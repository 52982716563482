import { Component, OnInit } from '@angular/core';
import { tipo_transaccion, movimiento_cotizacion, formato } from './constants';
import { MatDialog } from '@angular/material/dialog';
import { BuscadorTipoComponent } from 'src/app/administracion/mantenimiento/tipo/buscador-tipo/buscador-tipo.component';
import { TipoCategoriaEnum } from 'src/app/enums/tipo-categoria-enum';
import { ComunService } from 'src/app/services/comun-service';

@Component({
  selector: 'app-formato-template',
  templateUrl: './formato-template.component.html',
  styleUrls: ['./formato-template.component.css']
})
export class FormatoTemplateComponent implements OnInit {
  public tipoCategoriaEnum = TipoCategoriaEnum;

  headerTipoTransaccion : any = ['Código de tipo', 'Nombre de Tipo', 'Descripción', 'activo']
  headerMovimientoCotizacion : any = ['Movimiento', 'Descripción', 'Cotizacion', 'Descripción']
  // headerFormato : any = ['Campo', 'Tipo', 'Descripción', 'Formato','Cantidad Máxima de Caracteres', 'Cantidad Mínima de Dígitos Enteros', 'Cantidad Máxima de Dígitos Enteros', 'Cantidad Mínima de Dígitos Decimales', 'Cantidad Máxima de Dígitos Decimales'];
  headerFormato : any = ['Campo', 'Tipo', 'Descripción', 'Formato','Cant. Máx. de Caracteres', 'Cant. Mín. de Dígitos Enteros', 'Cant. Máx. de Dígitos Enteros', 'Cant. Mín. de Dígitos Decimales', 'Cant. Máx. de Dígitos Decimales'];
  dataTipoTransaccion = tipo_transaccion
  dataMovimientoCotizacion = movimiento_cotizacion
  dataFormato = formato
  idCategTipo: number;

  constructor(private dialog: MatDialog,
    private comunService: ComunService) {}


  getIdCategoriaTipoTransaccion(): void {
    const filtro = {
      filters: JSON.stringify({
        codCategTipo: this.tipoCategoriaEnum.CodigoTipoTransaccion,
      }),
    };

    this.comunService.get(filtro, "dbo.categoria-tipo").subscribe((data) => {
      this.idCategTipo = data.rows[0].idCategTipo;
    });
  }

  openTipoDialog() {
    const dialogRef = this.dialog.open(BuscadorTipoComponent, {
      data: {
        idCategTipo: this.idCategTipo ,
      },
      width: "900px",
      height: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.nombreTransaccion = result.nombreTipo;
      }
    });
  }

  ngOnInit(): void {
    this.getIdCategoriaTipoTransaccion();
  }

}
