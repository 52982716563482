import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of, Subscription } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { catchError, tap } from 'rxjs/operators';
import { JwtDto } from '../administracion/models/jwt-dto';
import { LoginUsuario } from '../administracion/models/login-usuario';
import { Servers } from '../config/api';
import { handleError } from '../util/error-handler';
import { EventEmitterService } from './event-emitter.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AutenticacionService {
  constructor(private http: HttpClient, 
    private tokenService: TokenService,
    private userIdle: UserIdleService,
    private eventEmitterService: EventEmitterService,
    private permissionsService: NgxPermissionsService) {}

  private oauthUrl = Servers.Contabilidad.baseLogin;
  private logoutUrl = Servers.Contabilidad.baseLogout;
  private logueado: boolean = false;
  tokenSubscription = new Subscription()

  // login POST sin body
  loginOauth(parametros: any): Observable<any> {
    let param =
      '?username=' + parametros.username + '&password=' + parametros.password;
    return this.http.post<any[]>(this.oauthUrl + param, null).pipe(
      tap(
        (data) => console.log(data),
        (error) => console.log(error)
      ),
      catchError(handleError('loginOauth', {}))
    );
  }

  logout_org() {
    return this.http.get<any[]>(this.logoutUrl).pipe(
      tap(
        (data) => console.log(data),
        (error) => console.log(error)
      ),
      catchError(handleError('logout', {}))
    );
  }

  logout() {
    this.tokenSubscription.unsubscribe();
    this.http.get<any[]>(this.logoutUrl).subscribe();
    this.tokenService.logOut();
    this.userIdle.stopWatching();
    this.permissionsService.removePermission('user');
  }

  setLogueado(param: boolean) {
    this.logueado = param;
  }

  getLogueado() {
    return this.logueado;
  }

  public login(loginUsuario: LoginUsuario): Observable<JwtDto> {
    return this.http.post<JwtDto>(this.oauthUrl, loginUsuario);
  }

  expirationCounter(timeout) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
      this.eventEmitterService.salir();
    });
  }
  
}
