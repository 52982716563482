import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { deleteEmptyData } from 'src/app/commons/app-utils';
import { ComunService } from 'src/app/services/comun-service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EstadoEnum } from 'src/app/enums/estado-enums';
import { TokenService } from 'src/app/services/token.service';

export interface DialogData {
  idAsiento: number;
}

@Component({
  selector: 'app-rechazar-asiento-contable',
  templateUrl: './rechazar-asiento-contable.component.html'
})
export class RechazarAsientoContableComponent implements OnInit {

  rejectForm: FormGroup;
  loadingPost = false;
  public estadoEnum = EstadoEnum;
  idEstadoRechazadoAutorizante: any;

  constructor(private formBuilder: FormBuilder,
              private comunService: ComunService,
              private toastrServices: ToastrService,
              private tokenService: TokenService,
              private router: Router,
              public dialogRef: MatDialogRef<RechazarAsientoContableComponent>,
              @Inject(MAT_DIALOG_DATA) public dataInput: DialogData) {
    console.log(this.dataInput.idAsiento);
    this.rejectForm = this.formBuilder.group({
      comentario: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getIdEstadoRechazadoAutorizante();
  }

  clearForm(){
    this.rejectForm = this.formBuilder.group({
      comentario: ['']
    });
  }

  getIdEstadoRechazadoAutorizante(){
    let estados = [];
    let filtro = {
      filters: JSON.stringify({ "codEstado": this.estadoEnum.RechazadoAutorizanteAsiento })
    };
    this.comunService.get(filtro, 'dbo.estado').subscribe((data) => {
      estados = data.rows;
      for(let i = 0; i < estados.length; i++){
          this.idEstadoRechazadoAutorizante = estados[i].idEstado;
      }
    });
  }

  rechazar(){
    let data = deleteEmptyData(this.rejectForm.value);
    data.idAsiento = this.dataInput.idAsiento;
    data.estadoAsientoId = this.idEstadoRechazadoAutorizante;  //asiento-rechazado
    data.idUsuarioModif = 1;
    data.fechaModif = new Date();
    data.comentario = this.rejectForm.get('comentario').value;

    let postData = {
      asientoContable : {
        idUsuarioModif: this.tokenService.getUserId(),
        fechaModif: new Date(),
        idAsiento: this.dataInput.idAsiento,
        estadoAsientoId: this.idEstadoRechazadoAutorizante
      },
      histEstadoAsiento: {
        idAsiento: this.dataInput.idAsiento,
        estadoAsiento: this.idEstadoRechazadoAutorizante,
        comentario: this.rejectForm.get('comentario').value,
        idUsuarioModif: this.tokenService.getUserId(),
        fechaModif: new Date()
      }
    }

    let url = 'dbo.asiento-contable/actualizar-historico-estado';
    this.loadingPost = true;
    this.comunService.put(postData, url)
    .subscribe(
      (rs: any) => { },
      err => {
        this.loadingPost = false;
        if (err.status == 400) {
          this.toastrServices.error(err.error, err.statusText);
        } else {
          this.toastrServices.error(err.error.messages, err.statusText);
        }
      },
      () => {
        this.loadingPost = false;
        this.toastrServices.success('Registro guardado con exito');
        this.clearForm();
        this.dialogRef.close();  
        this.router.navigate(['contabilidad/asiento-contable/']);
      }
    );

  }
  

}
