import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { TokenService } from '../services/token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router,
        private tokenService: TokenService) { }

        realRol : string;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
       const expectedRol = route.data.expectedRol;
       const roles = this.tokenService.getAuthorities();
       this.realRol = 'user';

        if (!this.tokenService.getToken()) {
            this.router.navigate(['/pages/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
       
        return true;
     
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return this.canActivate(route, state);
      }
}