import * as cloneDeep from "lodash/cloneDeep";
export const API_Session = "/proyecto-contabilidad-entities/api/session/";
export const SERVICE_SESSION = API_Session;
export const REST_HOST = "proyecto-contabilidad-entities/";
export const REST_HOST_OLD = "SFG-web/";
export const SERVICE_REST = REST_HOST;
export const SERVICE_REST_OLD = REST_HOST_OLD;

export const VARGLOBALRESPONSE = -1;
export const ID_ROL_ADMIN = 2;
import * as moment from "moment";

//cuenta contable
export const LONG_CON_FORMATO_CC = 20;
export const LONG_SIN_FORMATO_CC = 15;
export const FORMATO = "#.###.###.###.###.##";

export function parseDate(data) {
  if (data != null && data !== "") {
    for (const key in data) {
      if (
        data[key] != null &&
        data[key].year != null &&
        data[key].year !== ""
      ) {
        data[key] =
          data[key].year + "/" + data[key].month + "/" + data[key].day;
      }
    }
  }
  return data;
}

export function makeParam(data) {
  let parametros = cloneDeep(data);
  parametros = parseDate(parametros);
  parametros = deleteEmptyData(parametros);
  return parametros;
}

export function isEmpty(arg) {
  for (var item in arg) {
    return false;
  }
  return true;
}

export function error(arg) {
  if (arg === -1) {
    return true;
  }
  return false;
}
export function parseErr(err) {
  if (err.status != 500) {
    this.toastrService.error(err.error.messages[0], err.statusText);
  } else {
    this.toastrService.error(err.error.messages[0], err.statusText);
  }
  return;
}

export function deleteEmptyData(data) {
  for (const key in data) {
    if (data[key] == "" || data[key] == null) {
      delete data[key];
    }
  }
  return data;
}

export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  // hours = '' + d.getHours(),
  // minutes = '' + d.getMinutes(),
  // seconds = '' + d.getSeconds();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  // if (hours.length < 2) hours = '0' + hours;
  // if (minutes.length < 2) minutes = '0' + minutes;
  // if (seconds.length < 2) seconds = '0' + seconds;
  return [year, month, day].join("-");
}

export function formatDateTime(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hours = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    seconds = "" + d.getSeconds();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;
  return (
    [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")
  );
}

export function formatDateDMMYYY(params) {}

export function dateColumnFormatter(params) {
  return params.value != null
    ? moment(params.value).format("DD/MM/YYYY hh:mm")
    : "";
}

export function estadosFormatter(params) {
  if (params.value == "ACTIV") {
    return "ACTIVO";
  } else {
    return "INACTIVO";
  }
}

export function sexoFormatter(params) {
  if (params.value == "MAS") {
    return "MASCULINO";
  } else if (params.value == "FEM") {
    return "FEMENINO";
  } else {
    return "INDISTINTO";
  }
}

export function estadoCivilFormatter(params) {
  if (params.value == "SOL") {
    return "SOLTERO";
  } else if (params.value == "CAS") {
    return "CASADO";
  } else if (params.value == "DIV") {
    return "DIVORCIADO";
  } else if (params.value == "VIU") {
    return "VIUDO";
  } else {
    return "INDISTINTO";
  }
}

export function tipoSociedadFormatter(params) {
  if (params.value == "IND") {
    return "INDIVIDUAL";
  } else if (params.value == "SA") {
    return "SOCIEDAD ANONIMA";
  } else {
    return "SOCIEDAD R LIMITADA";
  }
}

export function tipoPersoneriaFormatter(params) {
  if (params.value == "PERJUR") {
    return "JURIDICA";
  } else if (params.value == "PERFIS") {
    return "FISICA";
  } else {
    return "";
  }
}

export function separadoresDecimales(params) {
  if (params.value) {
    params.value = params.value.toString();
    params.value = params.value.replace(/\./g, ",");
    let respuesta = params.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return respuesta;
  } else {
    return params.value;
  }
}

export function separadores(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\./g, ",");
    let respuesta = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return respuesta;
  } else {
    return value;
  }
}

export function restringirNumeros(e) {
  let input;
  if (e.metaKey || e.ctrlKey) {
    return true;
  }
  if (e.which === 32) {
    return false;
  }
  if (e.which === 0) {
    return true;
  }
  if (e.which < 33) {
    return true;
  }
  input = String.fromCharCode(e.which);
  //return !!/[\d\s]/.test(input);
  return !/[^0-9,]/.test(input);
}

export function restringirNumerosEnteros(e) {
  let input;
  if (e.metaKey || e.ctrlKey) {
    return true;
  }
  if (e.which === 32) {
    return false;
  }
  if (e.which === 0) {
    return true;
  }
  if (e.which < 33) {
    return true;
  }
  input = String.fromCharCode(e.which);
  //return !!/[\d\s]/.test(input);
  return !/[^0-9]/.test(input);
}



export function format(value) {
  var num = value.replace(/\./g, "");
  if (!isNaN(num)) {
    num = num
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    num = num.split("").reverse().join("").replace(/^[\.]/, "");
    value = num;
  } else {
    alert("Solo se permiten numeros");
    value = value.replace(/[^\d\.]*/g, "");
  }
}

export function formateaCuentaContable(value) {
  let cantidad = 20 - LONG_CON_FORMATO_CC;
  let cuentaContableAuxiliar = "";
  //completar con ceros a derecha, si es necesario
  let maximaLongitudCuenta = 24;
  let cerosStr = "000000000000000000000000";
  let codigoCuentaStrAux = value.toString();
  let longitudCuentaStr = codigoCuentaStrAux.length;
  let cuentaContableValue = null;

  if (
    longitudCuentaStr > LONG_SIN_FORMATO_CC ||
    longitudCuentaStr > maximaLongitudCuenta
  ) {
    alert("Longitud del código de cuenta es mayor al permitido");
  } else {
    let cantidadCeros = LONG_SIN_FORMATO_CC - longitudCuentaStr;
    codigoCuentaStrAux += cerosStr.substr(0, cantidadCeros);
    cuentaContableValue = Number(codigoCuentaStrAux);

    let codigoCuentaStr = cuentaContableValue.toString();
    //console.log("codigoCuentaStr: "+codigoCuentaStr);
    let contador = 0;
    for (let i = 0; i < LONG_CON_FORMATO_CC; i++) {
      let subCuentaStr = FORMATO.substr(i, 1);
      //console.log('subCuentaStr: '+subCuentaStr);
      if (subCuentaStr == "#") {
        let subCuentaStrAux = codigoCuentaStr.substr(contador, 1);
        cuentaContableAuxiliar += subCuentaStrAux;
        contador += 1;
      }
      if (subCuentaStr == ".") {
        cuentaContableAuxiliar += ".";
      }
    }
    let espaciosEnBlanco = "";
    for (let i = 0; i < cantidad; i++) {
      espaciosEnBlanco += " ";
    }
    cuentaContableAuxiliar = espaciosEnBlanco + cuentaContableAuxiliar;
    console.log("cuenta contable formateado: " + cuentaContableAuxiliar);
  }
  return cuentaContableAuxiliar;
}

export function parseStringToFloat(numberAstext) {
  if (numberAstext) {
    let number = parseFloat(
      numberAstext.toString().replaceAll(".", "").replaceAll(",", ".")
    );
    return number;
  }
  return numberAstext;
}

export function parseFloatToString(number) {
  if (number) {
    return number.toString().replaceAll(".", ",");
  }
  return number;
}
