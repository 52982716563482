import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ComunService } from "src/app/services/comun-service";

@Component({
  selector: "app-buscador-tipo",
  templateUrl: "./buscador-tipo.component.html",
  styleUrls: ["./buscador-tipo.component.css"],
})
export class BuscadorTipoComponent implements OnInit {
  searchForm: FormGroup;

  // MatPaginator Inputs
  length = 100;
  pageSize = 100;
  pageIndex: any;
  urlOrder: any;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  countG: number = 100;
  pageG: number = 0;

  dataRows: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      idCategTipo: string;
    },
    public dialogRef: MatDialogRef<BuscadorTipoComponent>,
    private comunService: ComunService,
    private formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      codTipo: [""],
      nombreTipo: [""],
      idCategTipo:[""]
    });
  }

  ngOnInit(): void {
    this.pageG = 0;
    this.countG = 100;
    this.apiService();
  }

  apiService() {
    let sortBy = "idTipo";
    let sortOrder = "ASC";
    this.searchForm.patchValue({
      idCategTipo: this.data.idCategTipo 
    });
 
    let filtro = {
      count: this.countG,
      page: this.pageG,
      sortBy: sortBy,
      sortOrder: sortOrder,
      filters: JSON.stringify(this.searchForm.getRawValue()),
    };

    this.comunService.get(filtro, "dbo.tipo").subscribe((data) => {
      this.length = data.count;
      this.dataRows = data.rows;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearForm() {
    this.apiService();
  }

  callPagination(event) {
    this.countG = event.pageSize;
    //console.log('count: '+this.countG);
    this.pageG = (event.pageSize * (event.pageIndex-1)) + event.pageSize;
    //console.log('page: '+this.pageG);
    this.apiService();
  }
}
