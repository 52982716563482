import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { SpinnerService } from "../services/spinner.service";
import { TokenService } from "../services/token.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService {
  constructor(
    private tokenService: TokenService,
    private toastrServices: ToastrService,
    private spinnerService: SpinnerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinnerService.show();
    let intReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      intReq = req.clone({
        headers: req.headers
          .set("Authorization", "Bearer " + token)
          .append("Cache-Control", "no-store")
          .append("Pragma", "no-store"),
      });
    }
    return next.handle(intReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinnerService.hide();
          }
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.hide();
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            this.toastrServices.error(err.error.message);
          } else {
            switch (err.status) {
              case 0:
              //  console.log('error en el cliente', err);
                break;
              case 503:
                this.toastrServices.error("El servicio no está disponible");
                break;
              case 504:
                this.toastrServices.error("Tiempo de respuesta del servidor accedido");
                break;
              default:
                this.toastrServices.error(err.error.messages);
                break;
            }
          }
        }
      )
    );
  }
}
export const interceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
];
