import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SERVICE_REST, SERVICE_REST_OLD } from '../commons/app-utils';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ComunService {
  constructor(private http: HttpClient) {}

  get(params: any, path): Observable<any> {
    const headers = new HttpHeaders();
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.http.get(`${SERVICE_REST}api/` + path, { params: params });
  }

  getOld(params: any, path): Observable<any> {
    const headers = new HttpHeaders();
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.http.get(`${SERVICE_REST_OLD}api/` + path, { params: params });
  }

  getTotal(path): Observable<any> {
    let response;
    try {
      response = this.http.get(`${SERVICE_REST}api/` + path);
    } catch (e) {
      console.log('api discover failed ', e);
      throw new Error('failed to discover the api.');
    }
    return response;
  }

  getOtro(params: any, path): Observable<any> {
    return this.http.get(`${SERVICE_REST}api/` + path, { params: params });
  }

  getRoles(): Observable<any> {
    return this.http.get(`${SERVICE_REST}api/comun-tipos-roles`);
  }

  post(data: any, path): Observable<any> {
    return this.http.post(`${SERVICE_REST}api/` + path, data);
  }

  put(data: any, path): Observable<any> {
    return this.http.put(`${SERVICE_REST}api/` + path, data);
  }

  delete(data: any, path): Observable<any> {
    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key, data[key]);
    });
    return this.http.delete(`${SERVICE_REST}api/` + path, { params: data });
  }

  getFile(params: any, path): Observable<any> {
    const headers = new HttpHeaders();
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.http.get(`${SERVICE_REST}api/` + path, {
      params: params,
      responseType: 'blob',
      observe: 'response'
    }).pipe(timeout(7200000));
  }

  getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get(
        'content-disposition'
      );
      let result = contentDisposition.split(';')[1].trim().split('=')[1];
      filename = result.replace(/"/g, '');
    } catch (e) {
      filename = null;
    }
    return filename;
  }

  descargar(response: HttpResponse<Blob>, myType: string) {
    const filename: string = this.getFileName(response);
    if (filename) {
      const file = new Blob([response.body], { type: myType });
      const fileURL = URL.createObjectURL(file);
      const downloadLink = document.createElement('a');
      downloadLink.href = fileURL;
      downloadLink.download = filename;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      throw new Error('Usuario no autenticado');
    }
  }
}
