import { Observable, of } from 'rxjs';

export function handleError<T>(operationType = 'operation', result?: any) {
  return (error: any): Observable<any> => {

    // TODO: send the error to remote logging infrastructure
    //console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    let mensaje = error.error.mensaje;
    let code = error.status;

    if (operationType === 'loginAuth') {
      mensaje = error.error.error_description ? error.error.error_description : mensaje;
    } else if (operationType === 'codigoMensaje') {
      mensaje = error.error.mensaje;
      code = error.error.codigo;
    } else if (operationType === 'logout') {
      mensaje = "Sesión cerrada exitosamente";//modificar mensaje cuando el backend se corrija
    } else if (operationType === 'default') {
      if (error.error && error.error.mensaje && error.error.code) {
        mensaje = error.error.mensaje;
        code = error.error.code;
      }
    }
    // Let the app keep running by returning an empty result.
    return of({ error: true, code, mensaje });
  };
}
