import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { ConfirmDialogComponent } from "./confirm-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  public open(options) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
      },
      disableClose: true
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }

  public changeMsd(msg:string){
   
    if(this.dialogRef && this.dialogRef.componentInstance){
     
      this.dialogRef.componentInstance.data.message = msg;
    }
    
  }
}
