import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs-compat/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  salirComponentFunction = new EventEmitter();    
  subsVar: Subscription; 

  constructor() { }

  salir() {    
    this.salirComponentFunction.emit();    
  }   
}
