import { Pipe, PipeTransform } from '@angular/core';
/*
* Raise the value exponentially
* Takes an exponent argument that defaults to 1.
* Usage:
* value | exponentialStrength:exponent
* Example:
* {{ 2 | exponentialStrength:10}}
* formats to: 1024
*/
@Pipe({ name: 'separadorMiles' })
export class SeparadorMilesPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      let respuesta = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return respuesta;
    } else {
      return value;
    }
  }
}
