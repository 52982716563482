import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { UserIdleService } from "angular-user-idle";
import { Subscription } from "rxjs/Subscription";
import { ConfirmDialogService } from "./shared/confirm-dialog/confirm-dialog.service";
import { ConfirmationService } from "./util/confirmation/confirmation.service";
import { EventEmitterService } from "./services/event-emitter.service";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
  providers: [ConfirmationService],
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  private timeOut: number;
  constructor(
    private router: Router,
    private dialogService: ConfirmDialogService,
    private userIdle: UserIdleService,
    private eventEmitterService: EventEmitterService
  ) {
    this.timeOut = this.userIdle.getConfigValue().timeout;
  }

  ngOnInit() {
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName("body")[0];
        const modalBackdrop =
          document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });

    // Se actualiza el diálogo con la cuenta regresiva
    this.userIdle.onTimerStart().subscribe((count) => {
      console.log(count);
      if(count===1){
        this.showLogoutDialog();
      }
      this.updateDialogMsg(count);
    });


    // TerminÓ el tiempo sin presionar boton alguno en el diálogo
    this.userIdle.onTimeout().subscribe(() => {
      this.eventEmitterService.salir();
      window.location.reload();
    });
  }
 // muestra el diálogo
  private showLogoutDialog() {
    const options = {
      title: "Su sesión está a punto de caducar",
      message: `Caducará en ${this.timeOut} segundos`,
      cancelText: "SEGUIR CONECTADO",
      confirmText: "SI, SALIR",
    };

    this.dialogService.open(options);

    this.dialogService.confirmed().subscribe((confirmed) => {
      if (confirmed) {
        this.eventEmitterService.salir();
        window.location.reload();
      } else {
        this.userIdle.resetTimer();
      }
    });
  }
  
  //actualiza el mensaje en el diálogo
  private updateDialogMsg(count) {
    if (count) {
      this.dialogService.changeMsd(
        `Caducará en ${this.timeOut - count} segundos`
      );
    }
  }
}
