import {environment} from '../../environments/environment';

//Desarrollo
const url = '/proyecto-contabilidad-entities/api/';
const urlLogin = '/proyecto-contabilidad-entities/api/login';
const urlLogout = '/proyecto-contabilidad-entities/api/login/salir';
// desa01.konecta.com.py
/* const url = '/fogapy-contabilidad/proyecto-contabilidad-entities/api/';
const urlLogin = '/fogapy-contabilidad/proyecto-contabilidad-entities/login'; 
const urlLogout = '/fogapy-contabilidad/proyecto-contabilidad-entities/logout'; */

const Servers = {
  Contabilidad: {
    baseUrl: `${url}`,
    baseLogin: `${urlLogin}`,
    baseLogout: `${urlLogout}`
  }
};

export { Servers };
