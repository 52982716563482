export enum TipoCategoriaEnum{
    CodigoTipoTransaccion = 'CATTRANSAC',
    CodigoTipoAsiento = 'CATASIENTO',
    CodigoTipoCompraVenta = 'CATCOMVEN',
    CodigoTipoComprobante = 'CATTCOMPRO',
    CodigoTipoNaturalezaContable = 'CATNATURCU',
    CodigoTipoConfiguracion = 'CATTIPCONF',
    CodigoFormatoCampoConfiguracion = 'CATFORCAMC',
    CodigoAreaFogapy = 'CATAREA',
    CodigoCasosPagoGarantia = 'CATCASOGAR',
    CodigoFuncionesGarantias = 'CATFUNCIONGAR',
    CodigoMotivoGarantias = 'CATSOLICITUDPAGO',
    CodigoProcesoEstadoGarantia = 'CATPROCESOGARANTIA',
    CodigoAccionDevolverLegajo = 'DEVOLVERLEGAJOPAGO',
    CodigoAccionDevolverAnalisis = 'DEVOLVERANALISISPAGO',
    CodigoCategoriaTipoAccion = 'CATFUNCIONGAR',
    CodigoAccionProrroga = 'PRORROGA'
}