import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabla-formato',
  templateUrl: './tabla-formato.component.html',
  styleUrls: ['./tabla-formato.component.css']
})
export class TablaFormatoComponent implements OnInit {

  @Input() header: any;
  @Input() data: any;

 

  constructor() { }

  ngOnInit(): void {
  }
  sort(a,b){
    return 0;
  }
}
