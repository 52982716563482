import { Component, OnInit } from "@angular/core";
import { ComunService } from "src/app/services/comun-service";

@Component({
  selector: "app-footer-cmp",
  templateUrl: "footer.component.html",
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  version: string;

  constructor(private comunService: ComunService) {}

  ngOnInit(): void {
    this.getSistenVersion();
  }
  getSistenVersion() {
    const filtro = {};
      this.comunService.get(filtro, "dbo.configuracion-del-sistema/back-end-release").subscribe((data) => {
        this.version="Sistema de Fondo de Garantías V "+data.mensaje;
      });
  }
}
