import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { deleteEmptyData } from 'src/app/commons/app-utils';
import { ComunService } from 'src/app/services/comun-service';

declare var $: any;
declare interface TableData {
  headerRow: string[];
  dataRows: Array<any>;
}

@Component({
  selector: 'app-buscador-plan-de-cuenta',
  templateUrl: './buscador-plan-de-cuenta.component.html'
})
export class BuscadorPlanDeCuentaComponent implements OnInit{

  params: any;
  public tableData1: TableData;
  searchForm: FormGroup;
  // MatPaginator Inputs
  length = 100;
  pageSize = 0;
  pageIndex: any;
  urlOrder: any;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  countG: number = 5;
  pageG: number = 0;
  
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filtroPlanCuenta: string;
    },
    public dialogRef: MatDialogRef<BuscadorPlanDeCuentaComponent>,
    private comunService: ComunService,
    private formBuilder: FormBuilder
    
    ) {

      this.searchForm = this.formBuilder.group({
        codigoCuenta: [''],
        nombreCuenta: [''],
        nivelCuenta: [null],
        cuentaImputable: [''],
      });

  }

  ngOnInit() {
    this.pageG = 0;
    this.countG = 5;
    this.tableData1 = {
      headerRow: ['Código Cuenta', 'Nombre Cuenta', 'Nivel Cuenta', 'Cuenta Imputable'],
      dataRows: []
    };
    this.listarPlanesDeCuentas();
  }  

  listarPlanesDeCuentas() {
    let sortBy = 'idPlanCuenta';
    let sortOrder = 'ASC';
    let filtroPlanCuenta = (this.data && this.data.filtroPlanCuenta) ? this.data.filtroPlanCuenta : null;
    let filtro = {
      count: this.countG,
      page: this.pageG,
      sortBy: sortBy,
      sortOrder: sortOrder,
      filters: JSON.stringify(filtroPlanCuenta)
    };
    this.comunService.get(filtro, 'dbo.plan-cuenta/listar-planes-cuentas-con-tipos').subscribe(response => {
      if (response) {
        this.length = response.count;
        this.tableData1.dataRows = [];
        response.rows.forEach(element => {
          this.tableData1.dataRows.push(element);
        });
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearForm() {
    this.listarPlanesDeCuentas();
  }

  apiServices(disparaEvento: boolean) {

    var filter = {}
    //console.log('disparaEvento: '+disparaEvento);
    if(disparaEvento == true){
      this.pageG = 0;
      this.countG = 5;
    }
    
    if (this.searchForm.value.codigoCuenta == ''
      && this.searchForm.value.nombreCuenta == ''
      && this.searchForm.value.nivelCuenta == null
      && this.searchForm.value.cuentaImputable == ''){
        filter = {};
    }else {
      filter = deleteEmptyData(this.searchForm.value);
    }


    if(this.data && this.data.filtroPlanCuenta){
       filter =Object.assign(filter, this.data.filtroPlanCuenta);
    }

    let sortBy = 'idPlanCuenta';
    let sortOrder = 'ASC';

    let filtro = {
      count: this.countG,
      page: this.pageG,
      sortBy: sortBy,
      sortOrder: sortOrder,
      filters: JSON.stringify(filter)
    };

    this.comunService.get(filtro, 'dbo.plan-cuenta/listar-planes-cuentas-con-tipos').subscribe((data) => {
      if (data) {
        this.length = data.count;
        this.tableData1.dataRows = [];
        data.rows.forEach(element => {
          this.tableData1.dataRows.push(element);
        });
      }

    });
  }

  callPagination(event) {
    this.countG = event.pageSize;
    //console.log('count: '+this.countG);
    //console.log('event.pageSize: '+event.pageSize);
    this.pageG = (event.pageSize * (event.pageIndex-1)) + event.pageSize;
    //console.log('page: '+this.pageG);
    //console.log('event.pageIndex: '+event.pageIndex);
    this.apiServices(false);
  }

}
