export const tipo_transaccion = [
    {
      "cod_tipo": "TIPTRASIAP",
      "nombre_tipo": "ASIENTO DE APERTURA",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRASICI",
      "nombre_tipo": "ASIENTO DE CIERRE",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRPROGE",
      "nombre_tipo": "PROVISIÓN DE GASTOS/EGRESOS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRPAPRO",
      "nombre_tipo": "PAGOS PROVEEDORES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRPROIM",
      "nombre_tipo": "PROVISIÓN DE PAGO DE IMPUESTOS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRPAIMP",
      "nombre_tipo": "PAGO DE IMPUESTOS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRPROPG",
      "nombre_tipo": "PROVISIÓN DE PAGO DE GARANTÍAS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRPAGGA",
      "nombre_tipo": "PAGO DE GARANTÍAS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRTRAEC",
      "nombre_tipo": "TRANSFERENCIAS ENTRE CUENTAS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRDERAP",
      "nombre_tipo": "DEPOSITO RECIBIDO A APLICAR",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRTRERA",
      "nombre_tipo": "TRANSFERENCIA DE RESULTADOS DEL EJERCICIO A RESULTADOS ACUMULADOS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRCAFOG",
      "nombre_tipo": "CAPITALIZACIÓN FOGAPY",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRMOPDE",
      "nombre_tipo": "MOVIMIENTO EN PREVISIONES/DESAFECTACIONES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRINIID",
      "nombre_tipo": "INGRESOS POR INVERSIONES/INGRESO DIFERIDO",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRCOFCC",
      "nombre_tipo": "COBRO FACTURA CREDITO COMISIONES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRDDIIT",
      "nombre_tipo": "DEVENGAMIENTO DIARIO DE INTERES - INVERSIONES EN TÍTULOS VALORES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRDDICA",
      "nombre_tipo": "DEVENGAMIENTO DIARIO DE INTERES - CAJA DE AHORRO A LA VISTA",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRMRDPA",
      "nombre_tipo": "MOVIMIENTO EN RECURSOS DISPONIBLES PARA ADJUDICAR",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRLGAIF",
      "nombre_tipo": "LINEAS DE GARANTÍAS ADJUDICADAS A IFPs",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRMGVIG",
      "nombre_tipo": "MOVIMIENTO EN GARANTÍAS VIGENTES (CARTERA GARANTIZADA)",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRMEGPA",
      "nombre_tipo": "MOVIMIENTO EN GARANTÍAS PAGADAS",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTROMCTC",
      "nombre_tipo": "OTROS MOVIMIENTOS EN CTAS CONTINGENCIA/ORDEN",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRADTIV",
      "nombre_tipo": "ADQUISIÓN DE TÍTULOS VALORES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRVECTV",
      "nombre_tipo": "VENCIMIENTO/COBRO DE TÍTULOS VALORES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRAPSCO",
      "nombre_tipo": "ASIENTO DE POSICION SOBRE COMPRADA",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTRAPSVE",
      "nombre_tipo": "ASIENTO DE POSICION SOBRE VENDIDA",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIPTR",
      "nombre_tipo": "ASIENTO DE VALUACION PATRIMONIALES",
      "descripcion": '',
      "activo": "S"
    },
    {
      "cod_tipo": "TIACTFONDO",
      "nombre_tipo": "ACTIVACION DE FONDO",
      "descripcion": "ACTIVACION DE FONDO",
      "activo": "S"
    },
    {
      "cod_tipo": "TIGARANTIAAMORTIZA",
      "nombre_tipo": "MOVIMIENTO EN GARANTÍAS VIGENTES (AMORTIZACION)",
      "descripcion": "MOVIMIENTO EN GARANTÍAS VIGENTES (AMORTIZACION)",
      "activo": "S"
    },
    {
      "cod_tipo": "TICOMISIONGARANTIA",
      "nombre_tipo": "COMISIONES POR USO DE GARANTIAS",
      "descripcion": "COMISIONES POR USO DE GARANTIAS",
      "activo": "S"
    },
    {
      "cod_tipo": "TIGARANTIACANCELA",
      "nombre_tipo": "MOVIMIENTO EN GARANTÍAS VIGENTES (CANCELACION)",
      "descripcion": "MOVIMIENTO EN GARANTÍAS VIGENTES (CANCELACION)",
      "activo": "S"
    },
    {
      "cod_tipo": "TIGARANTIAFORMALIZADA",
      "nombre_tipo": "MOVIMIENTO EN GARANTIAS FORMALIZADAS ",
      "descripcion": "MOVIMIENTO EN GARANTIAS FORMALIZADAS ",
      "activo": "S"
    },
    {
      "cod_tipo": "TISOLICITUDPAGOGARANTIA",
      "nombre_tipo": "SOLICITUD DE PAGO DE GARANTIAS",
      "descripcion": "SOLICITUD DE PAGO DE GARANTIAS",
      "activo": "S"
    },
    {
      "cod_tipo": "TIMOVGARANTIADESISTIDA",
      "nombre_tipo": "MOVIMIENTO EN GARANTÍAS VIGENTES (DESISTIDAS)",
      "descripcion": "MOVIMIENTO EN GARANTÍAS VIGENTES (DESISTIDAS)",
      "activo": "S"
    },
    {
      "cod_tipo": "TIGARANTIARENEG",
      "nombre_tipo": "GARANTIAS RENEGOCIADAS",
      "descripcion": "GARANTIAS RENEGOCIADAS",
      "activo": "S"
    },
    {
      "cod_tipo": "TIMOVGARANTIA3R",
      "nombre_tipo": "MOVIMIENTO EN GARANTIAS VIGENTES (PRODUCTO DE 3R)",
      "descripcion": "MOVIMIENTO EN GARANTIAS VIGENTES (PRODUCTO DE 3R)",
      "activo": "S"
    }
  ];


export const movimiento_cotizacion = [
    {
      "movimiento": "D",
      "descripcion": "Debe",
      "cotizacion": "C",
      "descripcion_1": "Precio compra"
    },
    {
      "movimiento": "H",
      "descripcion": "Haber",
      "cotizacion": "V",
      "descripcion_1": "Precio venta"
    }
  ];

export const formato = [
    {
      "campo": "fecha",
      "tipo": "Fecha",
      "Descripción": "Fecha del asiento contable.",
      "Formato": "dd/mm/aaaa",
      "Cantidad máxima caracteres": "-",
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "nro_asiento",
      "tipo": "Número entero",
      "Descripción": "Número que identifica a cada corte de asiento contable.Debe ser único por cada corte. Este número de asiento es solo utilizable en la planilla a subir, sin embargo dicho número no se verá reflejado en el sistema. Solo identifica a los asientos en el archivo, la identificación del asiento será la utilizada en el sistema una vez insertado los asientos.",
      "Formato": "#########",
      "Cantidad máxima caracteres": "-",
      "Cantidad mínima dígitos enteros": 1,
      "Cantidad máxima dígitos enteros": 7,
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "concepto",
      "tipo": "Texto libre",
      "Descripción": "Descripción del concepto del asiento contable.",
      "Cantidad máxima caracteres": 250,
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "moneda",
      "tipo": "Texto libre",
      "Descripción": "La moneda a la que corresponde el asiento contable.",
      "Formato": "USD ó GS",
      "Cantidad máxima caracteres": 3,
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "cotizacion",
      "tipo": "Texto libre",
      "Descripción": "Indica si se utiliza el precio compra o venta.",
      "Formato": "C ó V",
      "Cantidad máxima caracteres": 1,
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "tipo_transaccion",
      "tipo": "Texto libre",
      "Descripción": "Es el tipo de transacción al cual corresponde el asiento contable. Debe ser único por cada corte de asiento contable.",
      "Formato": "cod_tipo",
      "Cantidad máxima caracteres": 40,
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "movimiento",
      "tipo": "Texto libre",
      "Descripción": "Indica si el monto a ingresar corresponde al Debe o al Haber.",
      "Formato": "D ó H",
      "Cantidad máxima caracteres": 1,
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "cuenta_contable",
      "tipo": "Número con formato",
      "Descripción": "Es el número de la cuenta contable.",
      "Formato": "#.###.###.###.###.##",
      "Cantidad máxima caracteres": "-",
      "Cantidad mínima dígitos enteros": "-",
      "Cantidad máxima dígitos enteros": "-",
      "Cantidad mínima dígitos decimales": "-",
      "Cantidad máxima dígitos decimales": "-"
    },
    {
      "campo": "monto",
      "tipo": "Número con decimales",
      "Descripción": "Es el monto del asiento contable.",
      "Formato": "###############,##",
      "Cantidad máxima caracteres": "-",
      "Cantidad mínima dígitos enteros": 1,
      "Cantidad máxima dígitos enteros": 15,
      "Cantidad mínima dígitos decimales": 1,
      "Cantidad máxima dígitos decimales": 2
    }
  ];
