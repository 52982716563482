import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard/inicio',
        canActivate: [AuthGuard],
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                data: {expectedRol:['SFG_CONT_REG', 'SFG_CONT_AUT', 'SFG_INF_ADM']}
            },
            {
                path: 'contabilidad',
                loadChildren: './administracion/contabilidad/contabilidad.module#ContabilidadModule',
                data: {expectedRol:['SFG_CONT_REG', 'SFG_CONT_AUT', 'SFG_CONT_INF','SFG_INF_ADM']}

            },
            {
                path: 'reportes',
                loadChildren: './administracion/reportes/reportes.module#ReportesModule',
                data: {expectedRol:['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO']}

            },
            {
                path: 'garantia',
                loadChildren: './administracion/garantia/garantia.module#GarantiaModule',
                data: {expectedRol:['SFG_CONT_REG', 'SFG_CONT_AUT', 'SFG_CONT_INF','SFG_INF_ADM']}

            },
            {
                path: 'mantenimiento',
                loadChildren: './administracion/mantenimiento/mantenimiento.module#MantenimientoModule',
                data: {expectedRol:['ROLE_SFG_ADM_TI']}
            },
            {
                path: 'panelcontrol',
                loadChildren: './administracion/panel-control/panel-control.module#PanelControlModule',
                data: {expectedRol:['ROLE_SFG_ADM_TI', 'SFG_INF_ADM']}
            },
            {
                path: 'seguimientotarea',
                loadChildren: './administracion/seguimiento-tarea/seguimiento-tarea.module#SeguimientoTareaModule'


            },
            {
                path: 'configuracion',
                loadChildren: './administracion/configuracion/configuracion.module#ConfiguracionModule',
                data: {expectedRol:['SFG_CONFIGURADOR_SISTEMA', 'SFG_CONFIGURADOR_USUARIO', 'ROLE_SFG_ADM_TI']}
            }

        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];